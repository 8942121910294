import { Progress } from '@/components/ui'
import { useStore } from '@/store'
import { cn } from '@/util'
import { DotFilledIcon, CheckIcon, StarIcon } from '@radix-ui/react-icons'
import { default as toothFilledIcon } from '/assets/images/tooth-filled.svg'
import { default as toothIcon } from '/assets/images/tooth.svg'

interface IEncounterProgressProps {
  showDetails: boolean
}

export const EncounterProgress: React.FC<IEncounterProgressProps> = ({ showDetails }) => {
  const encounter = useStore(state => state.encounter)
  const activeTemplate = useStore(state => state.activeTemplate)
  const templateValues = useStore(state => state.templateValues)

  if (!encounter || !activeTemplate) {
    return null
  }

  const isCompletedSection = section => {
    if (activeTemplate.isHardCoded) {
      return section.requiredInfo.every(info => templateValues?.[info.variable])
    } else {
      return encounter.progress.sections[section.id]?.progress === 100
    }
  }

  const isCompletedInfo = info => {
    if (activeTemplate.isHardCoded && templateValues?.[info.variable]) {
      if (Array.isArray(templateValues[info.variable]) && templateValues[info.variable].length === 0) {
        return false
      }
      return true
    } else {
      return false
    }
  }

  let overallProgress = 0
  if (activeTemplate.isHardCoded) {
    const commonSections = activeTemplate.sections.filter(section =>
      encounter.progress.sections.hasOwnProperty(section.id)
    )
    const completedSections = commonSections.reduce(
      (accumulator, currentSection) => accumulator + (isCompletedSection(currentSection) ? 1 : 0),
      0
    )
    overallProgress = Math.round((completedSections / activeTemplate.sections.length) * 100)
  } else {
    const commonSections = Object.keys(encounter.progress.sections).filter(sectionId =>
      activeTemplate.sections.some(section => section.id === sectionId)
    )
    const totalProgress = commonSections.reduce(
      (accumulator, sectionId) => accumulator + encounter.progress.sections[sectionId].progress,
      0
    )
    overallProgress = Math.round(totalProgress / activeTemplate.sections.length)
  }

  return (
    <div className={cn('relative md:pt-6', showDetails && 'pt-6')}>
      <div className={cn('hidden flex-col gap-4 md:flex', showDetails && 'flex')}>
        {activeTemplate.sections.map(section => {
          return (
            <div key={section.id} className="flex flex-row gap-2">
              <img src={isCompletedSection(section) ? toothFilledIcon : toothIcon} className="size-6" />
              <div>
                <div className={cn('h-6 font-bold text-black', isCompletedSection(section) && 'text-[#5a71e4]')}>
                  {section.name}
                </div>
                {showDetails && (
                  <ul className="pt-2">
                    {section.requiredInfo.map(info => (
                      <li key={info.name} className="ml-0 flex text-sm">
                        {isCompletedInfo(info) ? (
                          <CheckIcon className="size-5 pt-0 text-[#5AE4C3]" />
                        ) : info.isStarIcon ? (
                          <StarIcon className="size-5 pt-0" />
                        ) : (
                          <DotFilledIcon className="size-5 pt-0  text-[#050a17]" />
                        )}
                        <span className={info.isStarIcon ? cn('bg-[#E0C300]') : cn('flex-1')}>{info.name}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className={cn('flex flex-row items-center gap-2 md:mt-8', showDetails && 'mt-8')}>
        <Progress className="bg-border" value={overallProgress} />
        <div className="text-[#00052f]">{overallProgress}%</div>
      </div>
    </div>
  )
}
